// developer custom styles

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
   -webkit-appearance: button;
}
.table__row-selected{
    background-color: aqua;
}
.table-wrapper{
    overflow: auto;
    margin-bottom: 1rem;
}