// Image coordinate points

.background-image__point{
  position: absolute;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: $red;
  transform: translate(-50%, -50%);
}
